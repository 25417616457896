import React from 'react';

interface StageProps {
  name: string;
  isActive: boolean;
}

const Stage: React.FC<StageProps> = ({ name, isActive }) => {
  const baseClasses = "flex gap-2 px-4 py-1.5 rounded-md";
  const activeClasses = isActive ? "text-white bg-orange-400" : "bg-gray-200";
  const arrowSrc = isActive ? "https://cdn.builder.io/api/v1/image/assets/TEMP/dbc6794b6ca5b23a72e472afdba115614f2972487d3e19f1a05467a499ae5a3b?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" : "https://cdn.builder.io/api/v1/image/assets/TEMP/e4455de7073604e3d7d2016d43a738f328823f6d446b8546ab40268ccf9b8afa?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&";

  return (
    <div className={`${baseClasses} ${activeClasses}`}>
      <div>{name}</div>
      <img loading="lazy" src={arrowSrc} alt="" className="shrink-0 my-auto w-2 aspect-[2]" />
    </div>
  );
};

const ProcessStages: React.FC = () => {
  const stages = [
    { name: "Design", isActive: false },
    { name: "Review", isActive: true },
    { name: "Approve", isActive: false },
  ];

  return (
    <div className="flex gap-5 py-1.5 pr-20 pl-4 text-sm font-semibold leading-5 whitespace-nowrap bg-gray-200 text-neutral-500 max-md:flex-wrap max-md:pr-5">
      {stages.map((stage, index) => (
        <Stage key={index} name={stage.name} isActive={stage.isActive} />
      ))}
    </div>
  );
};

export default ProcessStages;