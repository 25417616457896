import React from 'react';

interface StageSelectorProps {
  currentStage: string;
}

const StageSelector: React.FC<StageSelectorProps> = ({ currentStage }) => {
  return (
    <div className="flex flex-col justify-center items-start text-sm font-semibold leading-5 whitespace-nowrap bg-emerald-700 text-neutral-500 max-md:pr-5">
      <div className="flex flex-col justify-center max-w-full w-[108px]">
        <div className="flex gap-2 justify-center px-4 py-3 mr-auto ml-4 bg-white rounded-lg">
          <div>{currentStage}</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/579939c0af4d94d31a3ad562a2e72818f39b4eba155db8667dadb336d2875461?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 my-auto w-2 aspect-[2]" />
        </div>
      </div>
    </div>
  );
};

export default StageSelector;