import React, { Children, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BinaryFiles, DataURL, ExcalidrawImperativeAPI, ExcalidrawInitialDataState, ExcalidrawProps } from "@excalidraw/excalidraw/types";
import { ExcalidrawElement, ExcalidrawImageElement, FileId } from "@excalidraw/excalidraw/element/types";
import { Excalidraw } from "@excalidraw/excalidraw";
import { Footer } from "@excalidraw/excalidraw";
import "@excalidraw/excalidraw/index.css";
import type { OverlayModeFormData } from "@excalidraw/excalidraw/components/OverlayMode/OverlayMode";
import axios from 'axios';

// Extend the ExcalidrawProps interface
interface CustomExcalidrawProps extends ExcalidrawProps {
  overlaymodeSelect?: {
    selectOptions1: { label: string; value: string }[];
    selectOptions2: { label: string; value: string }[];
  };
  onOverlayModeSubmit?: (formData: OverlayModeFormData) => void;
}

export default function Playground() {
  const ExcalidrawComponent = Excalidraw as unknown as React.FC<CustomExcalidrawProps>;

  const location = useLocation();  // Access the location object
  const submissionKey = location.state?.submissionKey || 'submission1';  // Retrieve the submissionKey from state

  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const [currentKey, setCurrentKey] = useState(0); // Add a state to manage the key
  const [imageElements, setImageElements] = useState<{ [key: number]: readonly ExcalidrawElement[] }>({});
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [initialData, setInitialData] = useState<ExcalidrawInitialDataState | null>(null);
  const imageElementsRef = useRef<{ [key: number]: readonly ExcalidrawElement[] }>({});
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const imgComparisionUrl = process.env.REACT_APP_IMG_COMPARISON_BASE_URL;
  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;

  const selectOptions1 = [
    { label: `${submissionKey}-page${currentImageIndex + 1}`, value: `${submissionKey}-page${currentImageIndex + 1}` }
  ];
  
  const otherSubmissionKey = submissionKey === 'submission2' ? 'submission1' : 'submission1';
  const selectOptions2 = Object.keys(localStorage)
    .filter(key => key.startsWith(`${otherSubmissionKey}-page`))
    .map(key => {
      return { label: key, value: key };
    });
  
  const overlaymodeSelect = {
    selectOptions1,
    selectOptions2,
  };
  
  const handleOverlayModeSubmit = useCallback(async (formData: OverlayModeFormData) => {
    console.log("Overlay mode form data:", formData);
  
    const formDataToSend = new FormData();
  
    const file_1_url = localStorage.getItem(formData.currentSubmission);
    const file_2_url = localStorage.getItem(formData.comparisonSubmission);
  
    try {
      // Fetch the images from S3
      const [file1Response, file2Response] = await Promise.all([
        fetch(file_1_url as RequestInfo),
        fetch(file_2_url as RequestInfo)
      ]);
  
      // Convert the responses to Blob objects
      const file1Blob = await file1Response.blob();
      const file2Blob = await file2Response.blob();
  
      // Append the Blob objects to the FormData
      formDataToSend.append("file1", file1Blob, `${formData.currentSubmission}.png`);
      formDataToSend.append("file2", file2Blob, `${formData.comparisonSubmission}.png`);
      formDataToSend.append("color1", "0,0,255");
      formDataToSend.append("color2", "255,0,0");
  
      // Send the FormData using axios
      const response = await axios.post(`${imgComparisionUrl}/api/upload_png/`, formDataToSend);
      console.log("Success:", response.data);
      
      // Extract the updated image from the response
      const updatedImage = response.data.results[0].images.find((img: any) => img.type === 'Comb').image;

      // Update the image in Excalidraw
      const pageKey = formData.currentSubmission;
      const imageElement = {
      type: "image",
      id: pageKey,
      fileId: pageKey,
      status: "saved",
      x: 0,
      y: 0,
      width: 1920, // Set appropriate width
      height: 1080, // Set appropriate height
      isDeleted: false,
      fillStyle: "hachure",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 1,
      opacity: 100,
      angle: 0,
      version: 1,
      seed: Math.floor(Math.random() * 2 ** 31),
      versionNonce: 0,
      strokeColor: "#000000",
      backgroundColor: "transparent",
      roundness: null,
      groupIds: [],
      frameId: null,
      link: null,
      scale: [1, 1],
      locked: true,
      boundElements: null,
      updated: Date.now(),
      customData: {},
      isBinding: false,
      name: null,
    } as unknown as ExcalidrawImageElement;

    const newInitialData: ExcalidrawInitialDataState = {
      files: {
        [pageKey]: {
          id: pageKey,
          dataURL: `data:image/png;base64,${updatedImage}`,
          mimeType: "image/png",
          created: Date.now(),
        },
      } as BinaryFiles,
      elements: [imageElement],
    };

    setInitialData(newInitialData);
    setUpdateTrigger(prev => prev + 1);

    } catch (error: any) {
      console.error("Config:", error.config);
    }
  }, []);

  useEffect(() => {
    const loadImagesFromS3 = async () => {
      if (submissionKey) {
        const imageUrls: string[] = [];
        let pageNo = 1;
        while (true) {
          const s3Url = localStorage.getItem(`${submissionKey}-page${pageNo}`);
          if (!s3Url) break;
          imageUrls.push(s3Url);
          pageNo++;
        }

        if (imageUrls.length > 0) {
          setImages(imageUrls);
          try {
            const response = await fetch(imageUrls[0]);
            const blob = await response.blob();
            const dataUrl = await new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            });
            loadImage(dataUrl, 0, submissionKey); // Pass submissionKey here
          } catch (error) {
            console.error('Error loading image from S3:', error);
          }
        } else {
          console.error(`No images found in localStorage for submission key: ${submissionKey}`);
        }
      }
    };

    loadImagesFromS3();
  }, [submissionKey]);

  const saveToLocalStorage = useCallback((index: number, elements: readonly ExcalidrawElement[]) => {
    if (submissionKey) {
      localStorage.setItem(`${submissionKey}_excalidrawData_${index}`, JSON.stringify(elements));
    }
  }, [submissionKey]);

  const loadImage = useCallback((dataURL: string, index: number, submissionKey: string) => {
    const fileId = `localImage${index}` as FileId;
  
    const img = new Image();
    img.onload = () => {
      let elements: readonly ExcalidrawElement[] = [];
      
      // Try to load saved data from localStorage
      if (submissionKey) {
        const savedData = localStorage.getItem(`${submissionKey}_excalidrawData_${index}`);
        if (savedData) {
          try {
            const parsedData = JSON.parse(savedData);
            if (Array.isArray(parsedData) && parsedData.length > 0) {
              elements = parsedData;
            }
          } catch (error) {
            console.error("Error parsing saved data:", error);
          }
        }
      }
  
      const imageElement: ExcalidrawImageElement = {
        type: "image",
        id: fileId,
        fileId,
        status: "saved",
        x: 0,
        y: 0,
        width: img.width / 2,
        height: img.height / 2,
        isDeleted: false,
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        version: 1,
        seed: Math.floor(Math.random() * 2 ** 31),
        versionNonce: 0,
        strokeColor: "#000000",
        backgroundColor: "transparent",
        roundness: null,
        groupIds: [],
        frameId: null,
        link: null,
        scale: [1, 1],
        locked: true,
        boundElements: null,
        updated: Date.now(),
        customData: {},
        isBinding: false,
        name: null,
      } as unknown as ExcalidrawImageElement;
  
      // Always ensure the image element is the first element
      if (elements.length === 0 || elements[0].type !== "image") {
        elements = [imageElement, ...elements];
      } else {
        elements = [imageElement, ...elements.slice(1)];
      }
  
      imageElementsRef.current[index] = elements;
  
      const newInitialData: ExcalidrawInitialDataState = {
        files: {
          [fileId]: {
            id: fileId,
            dataURL: dataURL as DataURL,
            mimeType: "image/jpeg",
            created: Date.now(),
          },
        } as BinaryFiles,
        elements: elements,
      };
  
      setInitialData(newInitialData);
      setCurrentImageIndex(index);
      setUpdateTrigger(prev => prev + 1);
      
      // Save the initial state to localStorage
      saveToLocalStorage(index, elements);
    };
    img.src = dataURL;
  }, [submissionKey, saveToLocalStorage]);

  const handleImageLoad = useCallback((index: number) => {
    if (images[index]) {
      loadImage(images[index], index, submissionKey);
    }
  }, [images, loadImage, submissionKey]); 

  const onChange = useCallback((elements: readonly ExcalidrawElement[], appState: any) => {
    imageElementsRef.current[currentImageIndex] = elements;
    saveToLocalStorage(currentImageIndex, elements);
  }, [currentImageIndex, saveToLocalStorage]);

  useEffect(() => {
    if (images?.length > 0) {
      handleImageLoad(0);
    }
  }, [images, handleImageLoad]);

  if (!initialData) {
    return <div className="flex items-center justify-center mt-5">Loading...</div>;
  }

  return (
    <>
      {initialData && (
        <ExcalidrawComponent
          key={updateTrigger}
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={initialData}
          onChange={onChange}
          overlaymodeSelect={overlaymodeSelect} // Custom prop
          onOverlayModeSubmit={handleOverlayModeSubmit}
        >        
          <Footer>          
            <div className="Stack Stack_horizontal ml-auto mr-[0.6em]" style={{gap: 6}}>
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleImageLoad(index)}
                  className="whitespace-nowrap custom-footer bg-white shadow-md rounded-md px-3 py-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  Page {index + 1}
                </button>
              ))}
            </div>
          </Footer>
        </ExcalidrawComponent>
      )}  
    </>
  );
}
