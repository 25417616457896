import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';

const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

const keycloak = new Keycloak({
  url: keycloakUrl || '',
  realm: keycloakRealm || '',
  clientId: keycloakClientId || ''
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderApp = () => {
  root.render(
    <ReactKeycloakProvider 
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      }}
    >
      <App />
    </ReactKeycloakProvider>
  );
};

renderApp();

reportWebVitals();