import React from 'react';

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  return (
    <main className="flex flex-col ml-5 w-9/12 max-md:ml-0 max-md:w-full">
      {children}
    </main>
  );
};

export default MainContent;
