import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubmissionBar: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmissionClick = (submissionKey: string) => {
    const storedSubmission = localStorage.getItem(submissionKey+'-'+'page1');
    const destination = storedSubmission ? '/playground' : '/documents';
    navigate(destination, { state: { submissionKey } });
  };

  return (
    <div className="flex max-md:flex-wrap">
      <div className="flex flex-col justify-center px-4 py-1.5 text-sm font-semibold leading-5 text-emerald-700 whitespace-nowrap bg-white border-t border-r border-b border-zinc-300">
        <div className="flex gap-5 justify-between py-1 pr-2 pl-4 bg-emerald-200 rounded-lg">
          <div className="my-auto">Submission</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/955bf573a1901ee8e3cdcfc4ae7562b462087f674b5691fa4b88a3c0a1479d24?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-10 aspect-[1.67]" />
        </div>
      </div>
      <div className="flex flex-auto gap-5 pr-20 bg-white border-t border-b border-zinc-300 max-md:flex-wrap">
        <div className="flex gap-1 my-auto">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f237c9d2f4ffdcf7a88c6698f20235dc3da29af819abfef2c1af0ec5b2574a50?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-10 aspect-[1.67]" />
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ede50b5035f29af24ac4c055f91feec69035e8905572744943a4c978e5ba424c?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-6 aspect-square" />
        </div>
        <div className="flex gap-3 justify-center items-center px-4 py-1 border-b-2 border-emerald-700 max-md:flex-wrap">
          <div
            onClick={() => handleSubmissionClick('submission1')}
            className="cursor-pointer self-stretch my-auto text-sm font-semibold leading-5 text-neutral-700"
          >
            Submission 1
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300" />
          <div
            onClick={() => handleSubmissionClick('submission2')}
            className="cursor-pointer self-stretch my-auto text-sm font-semibold leading-5 text-neutral-700"
          >
            Submission 2
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300" />
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/271ec7200575d9e010b7a9f64adb8586b909c12a98f3d19d4a4299eb1a7964a8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-16 aspect-[2.63]" />
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/56f08a23b68f2f1e37bb8eb6f22f03ad5739d19be4e61477c9c5f86779122668?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-px border border-solid aspect-[0.05] border-zinc-300 stroke-[1px] stroke-zinc-300" />
          <div className="flex self-stretch">
            <div className="flex gap-2 px-2 py-1.5 rounded-md bg-orange-400 bg-opacity-50">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae440f934e8c9d75e6d240219b3d0c01206f952eb6720137929d62580efbf50b?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-6 aspect-square" />
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c02cdd36d90816137cae57a958e4f89590281d75f112045cbba8a987159d1ae?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 my-auto aspect-[1.22] w-[17px]" />
            </div>
            <div className="flex gap-2 p-2 text-xs font-semibold leading-5 text-amber-900 bg-orange-400 rounded-none">
              <div>Review in progress</div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/876616f7b9909ac91bf97a70ca31a2df564002696c4af017229fba6bb3ec1d61?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 my-auto w-2 aspect-[2]" />
            </div>
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3f1db387893fbea3c0bb2dcdef12bd318a982e7396d93d7d9f6807e19f7363a?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-2 aspect-[2]" />
        </div>
      </div>
    </div>
  );
};

export default SubmissionBar;
