import React from 'react';

interface SelectorProps {
  label: string;
  value: string;
}

const Selector: React.FC<SelectorProps> = ({ label, value }) => (
  <div className="flex gap-2 justify-center items-center px-6 py-1.5 bg-gray-200 rounded-lg max-md:px-5">
    <div className="self-stretch px-2 py-1 text-xs leading-4 text-emerald-700 whitespace-nowrap rounded-3xl bg-emerald-700 bg-opacity-30">
      {label}
    </div>
    <div className="self-stretch my-auto text-sm leading-5 text-neutral-500">
      {value}
    </div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4455de7073604e3d7d2016d43a738f328823f6d446b8546ab40268ccf9b8afa?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-2 aspect-[2]" />
  </div>
);

const TeamPartnerSelector: React.FC = () => {
  return (
    <div className="flex gap-2 pt-1.5 pr-20 pl-4 font-semibold bg-white max-md:flex-wrap max-md:pr-5">
      <Selector label="Team" value="Kang consultancy" />
      <Selector label="Partner" value="Naanal Innolab" />
    </div>
  );
};

export default TeamPartnerSelector;